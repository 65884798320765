<template>
  <div class="tipix-widget text-white" @click="redirectToTipixPage">
    <div class="tipix-picture border-white"
         :style="`background-image: url(${tipix.picture.getPath({w: 224, h: 224, crop: true})});`">
    </div>
    <div class="tipix-social-links">
      <template v-for="iconName in Object.keys(icons)">
        <img :key="iconName" :src="icons[iconName]" :alt="iconName" v-if="tipix[iconName]"
             @click.stop="open(iconName)"/>
      </template>
    </div>
    <div class="tipix-separator"></div>
    <div class="tipix-name font-bold text-center mt-2 whitespace-nowrap mx-auto">{{ tipix.firstname }} {{
        tipix.lastname
      }}
    </div>
    <div class="font-light text-sm text-center mb-2 mx-auto">{{ tipix.mission }}</div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { resolve } from '../../_helpers/image.helper';
import TextHelper from '../../_helpers/text.helper';

@Component({
  name: 'tipix-widget',
  props: {
    tipix: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        facebook: resolve('rs/Icone-RS_FB_fond.svg'),
        linkedin: resolve('rs/Icone-RS_Linkedin_fond.svg'),
        youtube: resolve('rs/Icone-RS_Youtube_fond.svg'),
        pinterest: resolve('rs/Icone-RS_Pinterest_fond.svg'),
        instagram: resolve('rs/Icone-RS_Insta_fond.svg'),
        twitter: resolve('rs/Icone-RS_Twitter_fond.svg'),
        tiktok: resolve('rs/Icone-RS_Tikok_fond.svg'),
      },
    };
  },
  computed: {},
  methods: {
    open(iconName) {
      if (!this.tipix[iconName]) return;
      window.open(this.tipix[iconName], '_blank').focus();
    },
    async redirectToTipixPage() {
      return this.$router.push(`/tipix/${this.tipix.id}-${TextHelper.slugify(this.tipix.firstname)}-${TextHelper.slugify(this.tipix.lastname)}`);
    },
  },
})
export default class TipixWidget extends Vue {
}
</script>

<style scoped>

</style>
