<template>
  <div class="page tipix-detail-page bg-primary-900 min-h-screen w-full pb-20 text-white">
    <template v-if="!loading && tipix">
      <!-- header -->
      <section class="hera-app-slider">
        <div class="slide-container" ref="slide">
          <div class="slide-item"
               v-for="image in sliderPictures"
               :class="{current: currentImage && currentImage.id === image.id}"
               :key="image.id"
               :style="`background-image: url(${image.picture.getPath(getWinParams())})`"></div>
        </div>
        <div class="slide-dots">
          <div class="dot-slider">
            <div class="dot-item"
                 v-for="image in sliderPictures"
                 :class="{current: currentImage && currentImage.id === image.id}"
                 @click="setCurrentPicture(image)"
                 :key="image.id"></div>
          </div>
        </div>
      </section>
      <!-- end of header -->
      <!-- social brands -->
      <section class="tipix-social-icons">
        <template v-for="iconName in Object.keys(icons)">
          <img :key="iconName" :src="icons[iconName]" :alt="iconName" v-if="tipix[iconName]"
               @click.stop="open(iconName)" class="cursor-pointer"/>
        </template>
      </section>
      <!-- end of social brands -->
      <!-- page content -->
      <section class="tipix-content">
        <div class="root-header">
          <div class="tipix-name font-title text-5xl">{{ tipix.firstname }} {{ tipix.lastname }}</div>
          <div class="titpix-mission font-bold text-xl">{{ tipix.mission }}</div>
        </div>
        <div class="blue-separator h-8px bg-primary-500 w-112px mb-6 mt-4"></div>
        <div class="tipix-web mb-2" v-if="tipix.web">Site Web :
          <a :href="tipix.web" class="app-link text-primary-500"
             target="_blank">{{ tipix.web.replace(/^http[s]?[:]\/\//, '') }}</a></div>
        <div class="tipix-registration italic opacity-80 mb-6">Tipix depuis {{ registrationDate }}</div>
        <div class="tipix-description p-6 rounded-xl border-2 border-primary-500 mb-6">« {{ tipix.description }} »</div>
        <div class="tipix-keywords">
          <bc-part-title title="Ses domaines d'expertises"></bc-part-title>
          <div class="keyword-list flex flex-wrap mt-4 mb-6">
            <div
                class="border rounded-full mr-2 mb-2 px-6 py-1 italic"
                v-for="keyword in keywords" :key="keyword.id">{{ keyword.label }}
            </div>
          </div>
        </div>
        <div class="tipix-modules">
          <bc-part-title title="Ses modules"></bc-part-title>
          <div class="module-widget-element">
            <div class="mw-container">
              <template v-for="module in tipix.modules">
                <router-link :key="module.id" v-slot="{ navigate }" v-if="module.isPaid" :to="{name: $routeNames.AccountModuleDetail, params: {id: module.id}}">
                  <module-minimal-widget @open="navigate"
                                         :module="module"></module-minimal-widget>
                </router-link>
                <router-link :key="module.id" v-slot="{ navigate }" v-if="!module.isPaid" :to="{name: $routeNames.ModulePage, params: {slug: module.slug}}">
                  <module-widget
                      @open="navigate"
                      :module="module"></module-widget>
                </router-link>
              </template>
            </div>
          </div>
        </div>
      </section>
      <!-- end of page content -->
    </template>
    <template v-if="loading || !tipix">
      <div class="flex">
        <loader size="128px" class="m-auto text-white"></loader>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import '../../assets/style/tipix-detail.scss';
import { Component } from 'vue-property-decorator';
import TipixWidget from '../../components/tipix/tipix-widget.vue';
import Loader from '../../components/loader.vue';
import { resolve } from '../../_helpers/image.helper';
import swipe from '../../_helpers/swipe-detector';
import TextHelper from '../../_helpers/text.helper';
import MetaHelper from '../../_helpers/meta.helper';

@Component({
  name: 'TipixDetail',
  metaInfo() {
    return this.meta;
  },
  components: {
    Loader,
    TipixWidget,
    BcPartTitle: () => import(/* webpackPrefetch: true */ '@/components/block-content/bc-part-title.vue'),
    ModuleWidget: () => import(/* webpackPrefetch: true */ '@/components/module-widget/module-widget.vue'),
    ModuleMinimalWidget: () => import(/* webpackPrefetch: true */ '@/components/module-widget/module-minimal-widget.vue'),
  },
  data() {
    return {
      dropdownOpenState: false,
      currentImage: null,
      icons: {
        facebook: resolve('rs/Icone-RS_FB_fond.svg'),
        linkedin: resolve('rs/Icone-RS_Linkedin_fond.svg'),
        youtube: resolve('rs/Icone-RS_Youtube_fond.svg'),
        pinterest: resolve('rs/Icone-RS_Pinterest_fond.svg'),
        instagram: resolve('rs/Icone-RS_Insta_fond.svg'),
        twitter: resolve('rs/Icone-RS_Twitter_fond.svg'),
        tiktok: resolve('rs/Icone-RS_Tikok_fond.svg'),
      },
    };
  },
  computed: {
    meta() {
      if (!this.tipix || !this.tipix.description) return MetaHelper.tipix();
      return MetaHelper.tipixDetail(`${this.tipix.firstname} ${this.tipix.lastname}`, this.tipix.description.slice(0, 150));
    },
    ...mapState({
      loading: (s) => s.tipixDetail.loading,
      tipix: (s) => s.tipixDetail.tipix,
    }),
    keywords() {
      return this.tipix.keywords;
    },
    registrationDate() {
      const month = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août',
        'septembre', 'octobre', 'novembre', 'décembre'][(new Date(this.tipix.created)).getMonth()];
      return `${month} ${(new Date(this.tipix.created)).getUTCFullYear()}`;
    },
    sliderPictures() {
      if (!this.tipix) return [];
      return this.tipix.tipixPictures.sort((a, b) => a.displayOrder - b.displayOrder);
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.load(to.params.id);
      }
    },
  },
  methods: {
    initSwipe() {
      swipe.swipeDetector(this.$refs.slide, {
        detectTop: false,
        detectBottom: false,
        prevent: false,
      });
      this.$refs.slide.addEventListener('element:swipe', (e) => {
        if (e.detail !== 'left' && e.detail !== 'right') return;
        const index = this.sliderPictures.map((p) => p.id).indexOf(this.currentImage.id);
        const next = index + (e.detail === 'right' ? 1 : -1);
        if (this.sliderPictures[next] === undefined) return;
        this.currentImage = this.sliderPictures[next];
      });
    },
    load(id) {
      this.$store.commit('tipixDetail/SET_LOADING', true);
      (async () => {
        await this.$store.dispatch('tipixDetail/fetch', id);
        const path = `/tipix/${id}-${TextHelper.slugify(this.tipix.firstname)}-${TextHelper.slugify(this.tipix.lastname)}`;
        if (this.$route.path !== path) {
          return this.$router.push(path);
        }
        if (this.sliderPictures.length === 0) return null;
        // eslint-disable-next-line prefer-destructuring
        this.currentImage = this.sliderPictures[0];
        this.initSwipe();
        return null;
      })().then().catch(console.error);
    },
    setCurrentPicture(image) {
      this.currentImage = image;
    },
    open(iconName) {
      if (!this.tipix[iconName]) return;
      window.open(this.tipix[iconName], '_blank').focus();
    },
    getWinParams() {
      return {
        w: Math.round(window.innerWidth / 2),
        h: Math.round(window.innerHeight),
      };
    },
  },
  async mounted() {
    this.load(this.$route.params.id);
  },
})
export default class TipixDetail extends Vue {
}
</script>

<style scoped>

</style>
